import "./css/common.css"
import "./css/AccordionTable.css"

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import SlideButton from 'react-slide-button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import toast, { Toaster } from 'react-hot-toast';
import React, { useState } from "react";

const AccordionTable = (props) => {
    const [open, setOpen] = useState(false);
    const [selectedChamberName, setSelectedChamberName] = useState("")
    const [selectedSystemSerialNo, setSelectedSystemSerialNo] = useState("")

    const handleClose = () => setOpen(false);

    const handleOpen = (systemSerialNo, chamberName) => {
        setSelectedSystemSerialNo(systemSerialNo);
        setSelectedChamberName(chamberName);
        setOpen(true);
    }

    const onActionButtonActivated = (systemSerialNo, chamberName, mode) => {
      handleClose();
      props.handleModeChange(systemSerialNo, chamberName, mode);
    }

    const onSetAllOfflineClicked = (systemSerialNo, system) => {
        toast.success("Setting all chambers to Offline for controller: " + systemSerialNo + ". Please be patient.");
        for (let i = 0; i < Object.entries(system.chamber_statuses).length; i++) {
            setTimeout( function () {
                props.handleModeChange(systemSerialNo, Object.entries(system.chamber_statuses)[i], "Offline", false);
            }, 4000)
        }
    }

    return (
        <div>
            {Object.entries(props.systems).map(([systemSerialNo, system]) => {
                let label = system.name ? systemSerialNo + " - " + system.name : systemSerialNo;
                let basicAccordionID = systemSerialNo + "-accordion";
                let chamberCount = (system.chamber_statuses) ? Object.keys(system.chamber_statuses).length : null;
                let activeChamberCount = 0;
                let activeChamberLabel = "(" + activeChamberCount + "/" + chamberCount + ")"
                console.log(activeChamberLabel)
                
		let sensorData = system.sensor_data
                let tempRhLabel = ""
                if (sensorData) {
                    let tempLabel = (sensorData.temperature) ? "T: " + sensorData.temperature + " (C)": ""
                    let rhLabel = (sensorData.humidity) ? "RH: " + sensorData.humidity + " (%)": ""
                    tempRhLabel = tempLabel + " " + rhLabel
                }
                return (
                    <Accordion id={basicAccordionID} disabled={!!system.error}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={basicAccordionID + "-content"}
                          id={basicAccordionID + "-header"}
                        >
                            <Grid container alignItems="center">
                                <Grid>
				    <div>{label}</div>
				    <div id="activeChamberCount"></div>
                                </Grid>
                                <Grid style={{marginLeft: 'auto'}}>
                                    {tempRhLabel}
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails id={basicAccordionID + "-details"} className="accordionTable-details">
                            <Box
                              m={1}
                              display="flex"
                              justifyContent="flex-end"
                              alignItems="flex-end"
                            >
                                <Button style={{margin: "2px", color: "white", "background-color": "#39B54A"}} size="medium" variant="contained" onClick={() => { onSetAllOfflineClicked(systemSerialNo, system) }}>Set All Chambers Offline</Button>
                            </Box>
                            <TableContainer component={Paper}>
                                <Table size="medium" padding="normal" aria-label={systemSerialNo + "-chamber-table"}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell align="right">Mode</TableCell>
                                            <TableCell align="right">Pressure</TableCell>
                                            <TableCell align="right">Leak Rate</TableCell>
                                            <TableCell align="right">Burn Rate</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            (system.chamber_statuses)
                                            ?  Object.entries(system.chamber_statuses).map(([chamberName, chamberStatus]) => {
                                                    let tableRowID = systemSerialNo + "-" + chamberName;
                                                    let backgroundColorClass = "table-row-notactive"
                                                    let pressure = chamberStatus.pressure
						    if (chamberStatus.mode !== "Offline") {
                                                        activeChamberCount += 1
						    }
                                                    if (chamberStatus.active) {
							backgroundColorClass = "table-row-active"
                                                        if (chamberStatus.mode === "Pump Down" && sensorData && sensorData.instantaneous_pressure) {
                                                            const rate_data = system.rate_data
                                                            if (rate_data && rate_data.rate_data) {
                                                                pressure = sensorData.instantaneous_pressure + " (" + rate_data.rate_data + ")"
                                                            }
                                                            else {
                                                                pressure = sensorData.instantaneous_pressure
                                                            }
                                                        }
                                                    }
					            document.getElementById("activeChamberCount").innerHTML = "(" + activeChamberCount + "/" + chamberCount + ")";
                                                    return (
                                                        <TableRow className={backgroundColorClass}
                                                                  key={tableRowID}
                                                                  id={tableRowID}
                                                                  onClick={() => handleOpen(systemSerialNo, chamberName)}>
                                                            <TableCell component="th" scope="row">
                                                                {chamberName}
                                                            </TableCell>
                                                            <TableCell
                                                                align="right">{chamberStatus.mode}</TableCell>
                                                            <TableCell
                                                                align="right">{pressure}</TableCell>
                                                            <TableCell
                                                                align="right">{chamberStatus.leak_rate}</TableCell>
                                                            <TableCell
                                                                align="right">{chamberStatus.burn_rate}</TableCell>
                                                        </TableRow>
                                                    );
                                            })
                                            : <TableRow><TableCell>Loading... Please be patient.</TableCell></TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </AccordionDetails>
                    </Accordion>
                )
            })}
            {/* TODO Maybe refactor the dialog into it's own component*/}
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="md"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {selectedSystemSerialNo}
                </DialogTitle>
                <DialogContent>
                    <ButtonGroup
                        buttonFlex={1}
                        fullWidth={true}
                    >
                        <Button style={{margin: "2px", color: "black", "border-color": "#7ac142"}} size="medium" variant="outlined" onClick={() => { onActionButtonActivated(selectedSystemSerialNo, selectedChamberName, "PumpDown") }}>Pump Down</Button>
                        <Button style={{margin: "2px", color: "black", "border-color": "#7ac142"}} size="medium" variant="outlined" onClick={() => { onActionButtonActivated(selectedSystemSerialNo, selectedChamberName, "Preservation") }}>Preservation</Button>
                        <Button style={{margin: "2px", color: "black", "border-color": "#7ac142"}} size="medium" variant="outlined" onClick={() => { onActionButtonActivated(selectedSystemSerialNo, selectedChamberName, "Offline") }}>Offline</Button>
                    </ButtonGroup>
                </DialogContent>
                <DialogActions>
                    <div style={{width:'100%'}}>
                        <SlideButton
                            mainText="Slide to Vent"
                            overlayText="Venting..."
                            classList="swipe-border-class"
                            caretClassList="swipe-caret-class"
                            overlayClassList="swipe-overlay-class"
                            minSlideWidth={0.8}
                            minSlideVelocity = {0.8}
                            onSlideDone={() => {  onActionButtonActivated(selectedSystemSerialNo, selectedChamberName, "Vent") }}
                        />
                    </div>
                </DialogActions>
            </Dialog>
            <Toaster />
        </div>
    )
}

export default AccordionTable;
